import clsx from 'clsx';
import type { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import { useMedia } from 'react-use';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CircleIcon } from '@endaoment-frontend/ui/icons';
import { Card, SwiperButtons } from '@endaoment-frontend/ui/shared';
import { stringToColor } from '@endaoment-frontend/utils';

import { TESTIMONIAL_SLIDES } from '../editable';

import { SectionLabel } from './SectionLabel';
import styles from './TestimonialCarousel.module.scss';

type TestimonialCardProps = { logo?: ReactNode; quote: string; name: string; from: string; href?: string };

export type TestimonialCards = ReadonlyArray<TestimonialCardProps>;

const TestimonialCard = ({ logo, quote, name, from, href }: TestimonialCardProps) => {
  return (
    <Card className={styles['testimonial-card']}>
      {logo ? (
        <div className={clsx(styles['testimonial-logo'])}>
          {href ? (
            <a href={href} title={from}>
              {logo}
            </a>
          ) : (
            logo
          )}
        </div>
      ) : (
        <div className={clsx(styles['testimonial-logo'])}>
          <CircleIcon
            startColor={stringToColor(from)}
            endColor={stringToColor(from.split('').reverse().join(''))}
            data-testid={`${from} avatar`}
            width='42'
            height='42'
          />
        </div>
      )}
      <div>
        <ReactMarkdown>{quote}</ReactMarkdown>
      </div>
      <span className={styles['testimonial-from']}>
        – {name}, {href ? <a href={href}>{from}</a> : from}
      </span>
    </Card>
  );
};

export const TestimonialCarousel = () => {
  const isMobile = useMedia('(max-width: 55rem)', true);

  return (
    <section className={styles['testimonial-carousel']}>
      <SectionLabel center text='Community Testimonials' />
      <Swiper
        direction='horizontal'
        slidesPerView={isMobile ? 1 : 3}
        spaceBetween={25}
        loop
        modules={isMobile ? [Autoplay, Pagination] : [Autoplay]}
        pagination={isMobile}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        speed={1000}>
        {TESTIMONIAL_SLIDES.map((props, i) => (
          <SwiperSlide key={i}>
            <TestimonialCard {...props} />
          </SwiperSlide>
        ))}
        <SwiperButtons outside />
      </Swiper>
    </section>
  );
};
