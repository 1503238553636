import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import Image from 'next/image';
import type { CSSProperties } from 'react';

import type { PortfolioCategory, UUID } from '@endaoment-frontend/types';
import { ArrowIcon } from '@endaoment-frontend/ui/icons';
import { Button, CardDotsBackground, TiltBox, cardClassNames } from '@endaoment-frontend/ui/shared';

import styles from './PortfolioCategoryCard.module.scss';
import { useTypeformModal } from './TypeformModal';

export const PortfolioCategoryCard = ({
  category,
  href,
  ...props
}: {
  href?: string;
  category: PortfolioCategory;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
}) => {
  const style = {
    '--card-color': category.colorRgb,
    '--card-color-half': `${category.colorRgb}7F`,
    '--card-color-quarter': `${category.colorRgb}3F`,
    '--card-color-tenth': `${category.colorRgb}19`,
    '--card-color-eight': `${category.colorRgb}23`,
    '--shadow-color': `0px 18px 26px 0px var(--card-color-tenth)`,
    ...props.style,
  };

  const sharedProps = {
    'className': clsx(cardClassNames.base, styles['portfolio-categories-card']),
    'data-color': category.colorRgb,
    'style': style,
  };
  const inner = (
    <>
      <Image src={category.logoUrl} alt={category.name} width={60} height={60} />
      <h3>{category.name}</h3>
      <p>{category.description}</p>
      <Button filled float={false} size='small' className={clsx(styles['portfolio-categories-card-button'])}>
        See Options <ArrowIcon color='white' />
      </Button>
      <CardDotsBackground className={styles['card-dots-background']} excludeCorner />
    </>
  );
  return (
    <TiltBox>
      {href ? (
        <Link href={href} {...sharedProps} {...props}>
          {inner}
        </Link>
      ) : (
        <div {...sharedProps} {...props}>
          {inner}
        </div>
      )}
    </TiltBox>
  );
};

export const PWPCategoryCard = () => {
  const { openTypeform: openPWPTypeform } = useTypeformModal({
    id: 'https://endaoment.typeform.com/private-wealth',
  });
  return (
    <PortfolioCategoryCard
      key='Private Wealth Management'
      onClick={openPWPTypeform}
      category={{
        id: '0' as UUID,
        name: 'Private Wealth Management',
        description: 'Select from our curated library of actively managed investment portfolios.',
        colorRgb: '#696f8c',
        logoUrl: 'https://storage.googleapis.com/endaoment-static/portfolio-categories/private-wealth-management.svg',
      }}
    />
  );
};
